var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar"},[_c('div',{staticClass:"navbar-header"},[_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"nav-logo"},[_c('img',{attrs:{"src":require("@/assets/images/Group 479.png"),"alt":"SILOHO"},on:{"click":_vm.closeMobileNav}})])]),_c('div',{staticClass:"hamburger",on:{"click":_vm.openMobileNav}},[_c('span',{staticClass:"bar"}),_c('span',{staticClass:"bar"}),_c('span',{staticClass:"bar"})])],1),_c('ul',{staticClass:"nav-menu"},[_c('router-link',{attrs:{"to":{
                name: 'AboutUsPage',
            }}},[_c('li',{staticClass:"nav-item",on:{"click":_vm.closeMobileNav}},[_vm._v(" About Us ")])]),_c('router-link',{attrs:{"to":{
                name: 'DesignIdeasListPage',
            }}},[_c('li',{staticClass:"nav-item",on:{"click":_vm.closeMobileNav}},[_vm._v(" Design Ideas ")])]),_c('router-link',{attrs:{"to":{ path: '/', hash: '#howitworks' }}},[_c('li',{staticClass:"nav-item",on:{"click":_vm.closeMobileNav}},[_vm._v(" How It Works ")])]),_c('router-link',{attrs:{"to":{ path: '/', hash: '#successstories' }}},[_c('li',{staticClass:"nav-item",on:{"click":_vm.closeMobileNav}},[_vm._v(" Success Stories ")])]),_c('router-link',{attrs:{"to":{
                name: 'BuildersListPage',
            }}},[_c('li',{staticClass:"nav-item",on:{"click":_vm.closeMobileNav}},[_vm._v(" Our Partners ")])]),_c('router-link',{attrs:{"to":{ path: '/', hash: '#vendors' }}},[_c('li',{staticClass:"nav-item",on:{"click":_vm.closeMobileNav}},[_vm._v(" Our Vendors ")])]),_c('li',{staticClass:"login-nav-mobile"},[(!_vm.auth)?_c('Button',{attrs:{"outline":"","name":"Sign in / Sign up"},nativeOn:{"click":function($event){return _vm.$emit('auth')}}}):_c('div',{staticClass:"a-login"},[_c('div',{staticClass:"user-name",on:{"click":function($event){_vm.showUserMenu = !_vm.showUserMenu}}},[_c('span',{staticClass:"user"},[_vm._v(" "+_vm._s(_vm.userData.firstname)+" ")]),_c('img',{staticClass:"profile-image",attrs:{"src":require("@/assets/images/profile.svg"),"alt":""}})]),(_vm.showUserMenu)?_c('div',{staticClass:"user-menu",on:{"click":_vm.closeMobileNav}},[_c('ul',{staticClass:"user-menu-options"},[_c('li',{staticClass:"user-item"},[_c('router-link',{staticClass:"item",attrs:{"to":{
                                    name: 'MyWorkspace',
                                },"active-class":"active"}},[_vm._v(" My Workspace ")])],1),_c('li',{staticClass:"user-item"},[_c('router-link',{staticClass:"item",attrs:{"to":{
                                    name: 'MyDesignIdeas',
                                },"active-class":"active"}},[_vm._v(" My Design Ideas ")])],1),_c('li',{staticClass:"user-item"},[_c('router-link',{staticClass:"item",attrs:{"to":{
                                    name: 'MyProfile',
                                },"active-class":"active"}},[_vm._v(" My Profile ")])],1),_c('li',{staticClass:"user-item"},[_c('router-link',{staticClass:"item",attrs:{"to":"/contact","active-class":"active"}},[_vm._v(" Contact Us ")])],1),_c('li',{staticClass:"user-item"},[_c('router-link',{attrs:{"to":"/"}},[_c('span',{staticClass:"logout",on:{"click":_vm.logOut}},[_vm._v("Logout")])])],1)])]):_vm._e()])],1),_c('li',{staticClass:"style-nav-mobile"},[(_vm.isSQ == true && _vm.isPQ == true)?_c('Button',{attrs:{"name":"Explore Designs","primary":"","target":"_self","handleClick":_vm.openMDI}}):(_vm.isSQ == true && _vm.isPQ == false)?_c('Button',{attrs:{"name":"Explore Designs","primary":"","handleClick":_vm.openMDI,"target":"_self"}}):_c('Button',{attrs:{"name":"Create project","primary":"","handleClick":_vm.openStyleQuiz,"target":"_self"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }