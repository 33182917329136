import { fetchEhdDetails } from 'api/ehdApi'

const $appModule = {
    state: () => ({
        ogTagDetails: {},
    }),

    mutations: {
        setOGTagDetails(state, payload) {
            state.ogTagDetails = payload
        },
    },

    getters: {},

    actions: {
        fetchOGTagEHDDetails({ commit }, id) {
            commit('setOGTagDetails', {})
            return fetchEhdDetails(id).then(response => {
                if (response.responseCode === 200) {
                    let ogTagData = response.data
                    commit('setOGTagDetails',ogTagData)
                }
            })
        },
    },
}

export default $appModule