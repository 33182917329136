<template>
    <div class="navbar">
        <div class="navbar-header">
            <router-link to="/">
                <div class="nav-logo">
                    <img
                        src="@/assets/images/Group 479.png"
                        @click="closeMobileNav"
                        alt="SILOHO"
                    />
                </div>
            </router-link>
            <div class="hamburger" @click="openMobileNav">
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
            </div>
        </div>

        <ul class="nav-menu">
            <router-link
                :to="{
                    name: 'AboutUsPage',
                }"
            >
                <li class="nav-item" @click="closeMobileNav">
                    About Us
                </li>
            </router-link>
            <router-link
                :to="{
                    name: 'DesignIdeasListPage',
                }"
            >
                <li class="nav-item" @click="closeMobileNav">
                    Design Ideas
                </li>
            </router-link>
            <router-link :to="{ path: '/', hash: '#howitworks' }">
                <li class="nav-item" @click="closeMobileNav">
                    How It Works
                </li>
            </router-link>

            <router-link :to="{ path: '/', hash: '#successstories' }">
                <li class="nav-item" @click="closeMobileNav">
                    Success Stories
                </li>
            </router-link>
            <router-link
                :to="{
                    name: 'BuildersListPage',
                }"
            >
                <li class="nav-item" @click="closeMobileNav">
                    Our Partners
                </li>
            </router-link>
            <router-link :to="{ path: '/', hash: '#vendors' }">
                <li class="nav-item" @click="closeMobileNav">
                    Our Vendors
                </li>
            </router-link>
            <li class="login-nav-mobile">
                <Button
                    outline
                    name="Sign in / Sign up"
                    @click.native="$emit('auth')"
                    v-if="!auth"
                />
                <div class="a-login" v-else>
                    <div
                        class="user-name"
                        @click="showUserMenu = !showUserMenu"
                    >
                        <span class="user">
                            {{ userData.firstname }}
                        </span>
                        <img
                            src="@/assets/images/profile.svg"
                            alt=""
                            class="profile-image"
                        />
                    </div>
                    <div
                        class="user-menu"
                        v-if="showUserMenu"
                        @click="closeMobileNav"
                    >
                        <ul class="user-menu-options">
                            <li class="user-item">
                                <router-link
                                    :to="{
                                        name: 'MyWorkspace',
                                    }"
                                    class="item"
                                    active-class="active"
                                >
                                    My Workspace
                                </router-link>
                            </li>
                            <li class="user-item">
                                <router-link
                                    :to="{
                                        name: 'MyDesignIdeas',
                                    }"
                                    class="item"
                                    active-class="active"
                                >
                                    My Design Ideas
                                </router-link>
                            </li>
                            <li class="user-item">
                                <router-link
                                    :to="{
                                        name: 'MyProfile',
                                    }"
                                    class="item"
                                    active-class="active"
                                >
                                    My Profile
                                </router-link>
                            </li>
                            <li class="user-item">
                                <router-link
                                    to="/contact"
                                    class="item"
                                    active-class="active"
                                >
                                    Contact Us
                                </router-link>
                            </li>
                            <li class="user-item">
                                <router-link to="/">
                                    <span class="logout" @click="logOut"
                                        >Logout</span
                                    >
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
            <li class="style-nav-mobile">
                <Button
                    v-if="isSQ == true && isPQ == true"
                    name="Explore Designs"
                    primary
                    target="_self"
                    :handleClick="openMDI"
                />
                <Button
                    v-else-if="isSQ == true && isPQ == false"
                    name="Explore Designs"
                    primary
                    :handleClick="openMDI"
                    target="_self"
                />
                <Button
                    v-else
                    name="Create project"
                    primary
                    :handleClick="openStyleQuiz"
                    target="_self"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Button from 'componentsv2/Button'

export default {
    name: 'NavBar',
    props: {
        auth: {
            type: Boolean,
        },
        logOut: {
            type: Function,
            default: () => {},
        },
        isSQ: Boolean,
        isPQ: Boolean,
    },
    components: {
        Button,
    },
    data() {
        return {
            url:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_QUIZ_BASE_URL_B2B
                    : process.env.VUE_APP_QUIZ_BASE_URL,
            baseUrl:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_BASE_URL_B2B
                    : process.env.VUE_APP_BASE_URL,
            showModal: false,
            showUserMenu: false,
            breadcrumbList: [],
        }
    },
    watch: {
        $route() {
            this.showUserMenu = false
            this.updateList()
        },
    },
    computed: {
        ...mapState({
            userData: state => state.AuthModule.userData,
        }),
    },
    methods: {
        ...mapActions({}),
        openMDI() {
            window.open(`${this.baseUrl}mudi/designideas`, '_self')
        },
        openStyleQuiz() {
            window.open(`${this.url}?startQuiz=true`, '_self')
        },
        toggleLoginModal() {
            this.showModal = !this.showModal
        },
        handleScroll(e) {
            let header = document.querySelector('.navbar')
            let sticky = header.offsetTop
            if (window.pageYOffset > sticky + 1) {
                header.classList.add('navbar-fixed')
                if (this.breadcrumbList == null) {
                    document.querySelector('#app').style.marginTop = '48px'
                } else {
                    document.querySelector('#app').style.marginTop = '52px'
                }
            } else {
                header.classList.remove('navbar-fixed')
                document.querySelector('#app').style.marginTop = '0px'
            }
        },

        // hamburger function //

        openMobileNav() {
            const hamburger = document.querySelector('.hamburger')
            const navMenu = document.querySelector('.nav-menu')

            hamburger.classList.toggle('active'),
                navMenu.classList.toggle('active')
            if (hamburger.classList.contains('active')) {
                document.querySelector('body').style.overflow = 'hidden'
            } else {
                document.querySelector('body').style.overflow = 'auto'
            }
        },
        closeMobileNav() {
            const hamburger = document.querySelector('.hamburger')
            const navMenu = document.querySelector('.nav-menu')
            document.querySelector('body').style.overflow = 'auto'
            hamburger.classList.remove('active')
            navMenu.classList.remove('active')
        },
        updateList() {
            this.breadcrumbList = this.$route.meta.breadcrumb
        },
    },
    created() {
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
}
</script>

<style lang="scss" scoped>
@import './NavBarMobile.scss';
</style>
