import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import store from '../store'

Vue.use(Router)
Vue.use(Meta)

const CustomScenePage = () => import('@/pages/CustomScenePage')
// const DownloadKitchenQuote = () => import('@/pagesV2/DownloadKitchenQuote')
const DesignerDetailsPage = () => import('@/pages/DesignerDetailsPage')
const LandingPage = () => import('@/pagesV2/LandingPage')
const GodrejGroup = () => import('@/pagesV2/GodrejGroup')
const CarmelHeights = () => import('@/pagesV2/CarmelHeights')
const NorthenHeights = () => import('@/pagesV2/NorthenHeights')
const CasaAmora = () => import('@/pagesV2/CasaAmora')
const DownloadBOQ = () => import('@/pagesV2/DownloadBOQ')
const DesignerDetailPortfolio = () =>
    import('@/components/DesignerDetailPortfolio')
const BuildersListPage = () => import('@/pages/BuildersListPage')
const BuilderDetailsPage = () => import('@/pages/BuilderDetailsPage')
const WorkFlowPage = () => import('@/pages/WorkFlowPage')
//----PagesV2 import ---//
const HomePageV2 = () => import('@/pagesV2/HomePage')
const DesignersListPage = () => import('@/pagesV2/DesignersListPage')
const BuilderDetailsPageV2 = () => import('@/pagesV2/BuilderDetailsPage')
const BuilderProjectDetailsPage = () =>
    import('@/pagesV2/BuilderProjectDetailsPage')
const DesignIdeasListPage = () => import('@/pagesV2/DesignIdeasListPage')
const ThemeDesignIdeasPage = () => import('@/pagesV2/ThemeDesignIdeasPage')
const RoomDesignIdeasPage = () => import('@/pagesV2/RoomDesignIdeasPage')
const SceneDetailsPage = () => import('@/pagesV2/SceneDetailsPage')
const TCPage = () => import('@/pagesV2/TCPage')
// const Privacy = () => import("@/pagesV2/Privacy");
const ProductListPage = () => import('@/pagesV3/ProductListPage')
const ProductDetailsPage = () => import('@/pagesV2/ProductDetailsPage')
const ExecutionPage = () => import('@/pagesV2/ExecutionPage')
const ShippingDetailsPage = () => import('@/pagesV2/ShippingDetailsPage')
const PaymentPage = () => import('@/pagesV2/PaymentPage')
const FAQPage = () => import('@/pagesV2/FAQPage')
const ContactPage = () => import('@/pagesV2/ContactPage')
const MudiPage = () => import('@/pagesV2/MudiPage')
const ThankYouPage = () => import('@/pagesV2/ThankYouPage')
const EHDPagev2 = () => import('@/pagesV2/EHDPage')
const EHDPage = () => import('@/pagesV3/EHDPage')
const EHDPageRefactor = () => import('@/pagesV3/EHDPageRefactor')
// const FinaliseAndPlaceOrderPage = () =>
//     import('@/pagesV2/FinaliseAndPlaceOrderPage')
const FinaliseAndPlaceOrderPage = () =>
    import('@/pagesV3/FinaliseAndPlaceOrderPage')
const AboutUsPage = () => import('@/pagesV2/AboutUsPage')
const AffiliatePage = () => import('@/pagesV2/AffiliatePage')
const AffiliateHomePage = () => import('@/pagesV2/AffiliateHomePage')
// const CustomisationPage = () => import("@/pagesV2/CustomisationPage");
const PostSqCustomisation = () => import('@/pagesV2/PostSqCustomisation')
const KitchenFilter = () => import('@/pagesV2/KitchenFilterPage')
const ErrorPage = () => import('@/pagesV2/404')
const MyDesignIdeas = () => import('@/componentsV2/MyDesignIdeas')
const MyWorkspace = () => import('@/componentsV2/MyWorkspace')
const MyProfile = () => import('@/componentsV2/MyProfile')
const ExecutionCard = () => import('@/componentsV2/ExecutionCard')
const KitchenCustomisationPage = () =>
    import('@/pagesV2/KitchenCustomisationPage')
const ExecutionAllTask = () => import('@/componentsV2/ExecutionAllTask')
const OGTagExp = () => import('@/pagesV2/OGTagExp')

function routeGuard(to, from, next) {
    console.log(to)
    if (store.state.AuthModule.isLoggedIn) {
        next()
    } else {
        store.dispatch('toggleShowAuthModal', true)
        const watchGuard = store.watch(
            () => store.state.AuthModule.isLoggedIn,
            loggedin => {
                if (loggedin) {
                    return next()
                }
                watchGuard()
            },
        )
    }
}

export default new Router({
    base: '/',
    routes: [
        // {
        //     path: '/custom-scene/:id',
        //     name: 'CustomScenePage',
        //     component: CustomScenePage,
        // },
        {
            path: '/designer/:id/details',
            name: 'DesignerDetailsPage',
            component: DesignerDetailsPage,

            children: [
                {
                    path: 'portfolio',
                    name: 'DesignerDetailPortfolio',
                    component: DesignerDetailPortfolio,
                    meta: {
                        breadcrumb: [
                            { name: 'Designers', link: '/designers' },
                            { name: 'Breadcrumb' },
                        ],
                    },
                },
                // {
                //     path: 'portfolio-group/:group_id',
                //     name:'DesignerDetailGroupDesktop',
                //     component: DesignerDetailGroupDesktop
                // },
            ],
        },
        // {
        //     path: '/m/portfolio-group/:group_id',
        //     name:'DesignerDetailGroupMobile',
        //     component: DesignerDetailGroupMobile
        // },
        {
            path: '/events/dhartigroup/pressidio-tea-party/',
            name: 'LandingPage',
            component: LandingPage,
        },
        {
            path: '/events/godrej-properties',
            name: 'GodrejGroup',
            component: GodrejGroup,
        },
        {
            path: '/events/carmel-heights',
            name: 'CarmelHeights',
            component: CarmelHeights,
        },
        {
            path: '/download-boq',
            name: 'DownloadBOQ',
            component: DownloadBOQ,
            beforeEnter: routeGuard,
        },
        // {
        //     path: '/download-kitchen-quote',
        //     name: 'DownloadKitchenQuote',
        //     component: DownloadKitchenQuote,
        //     beforeEnter: routeGuard,
        // },
        {
            path: '/builders/',
            name: 'BuildersListPage',
            component: BuildersListPage,
        },
        {
            path: '/builder/:id',
            name: 'BuilderDetailsPage',
            component: BuilderDetailsPage,
            meta: {
                breadcrumb: [
                    { name: 'Builders', link: '/builders' },
                    { name: 'Breadcrumb' },
                ],
            },
            // beforeEnter: (to, from, next) => {
            // function isValid (id) {
            //   return store.getters.resourceByID(id) !== undefined
            // }
            //console.log(to.params.id, 'Builder ID')
            //console.log(store.state.BuildersListPageModule.buildersList.developer_data.id )
            ///console.log(store.state.AuthModule.isLoggedIn,'store')  return store.getters.resourceByID(id) !== undefined
            // if (!isValid(to.params.id)) {
            //   next({ name: '404' });
            // }
            // next();
            //if(to.params.id === undefined){
            //if(to.fullPath.includes('/'+to.params.id)){
            //next({ name: '404' });
            //}else{
            // next();
            //}
            // }
        },

        // {
        //     path:'/workflow',
        //     name:'WorkFlowPage',
        //     component: WorkFlowPage,
        // },

        //----V2----//
        {
            path: '/',
            name: 'Home',
            component: HomePageV2,
            // children: [
            //     {
            //         path: '/Login',
            //         name: 'LoginBox',
            //         component: LoginBox,
            //     }
            // ]
        },
        {
            path: '/designers',
            name: 'DesignersListPage',
            component: DesignersListPage,
        },
        {
            path: '/buildersv2/:id',
            name: 'BuilderDetailsPageV2',
            component: BuilderDetailsPageV2,
        },
        {
            path: '/builder/project/:id',
            name: 'BuilderProjectDetailsPage',
            component: BuilderProjectDetailsPage,
        },
        {
            path: '/designideas',
            name: 'DesignIdeasListPage',
            component: DesignIdeasListPage,
        },
        {
            path: '/modular/kitchen',
            name: 'KitchenCustomisationPage',
            component: KitchenCustomisationPage,
        },
        {
            path: '/designideas/theme/:id',
            name: 'ThemeDesignIdeasPage',
            component: ThemeDesignIdeasPage,

            meta: {
                breadcrumb: [
                    { name: 'Design Idea', link: '/designideas' },
                    { name: 'Breadcrumb' },
                ],
            },
        },

        {
            path: '/designideas/room/:id',
            name: 'RoomDesignIdeasPage',
            component: RoomDesignIdeasPage,

            meta: {
                breadcrumb: [
                    { name: 'Design Idea', link: '/designideas' },
                    { name: 'Breadcrumb' },
                ],
            },
        },
        {
            path: '/scene/:id',
            name: 'SceneDetailsPage',
            component: SceneDetailsPage,
        },
        {
            path: '/product/details/:id',
            name: 'ProductDetailsPage',
            component: ProductDetailsPage,
        },
        {
            path: '/execution/:id',
            component: ExecutionPage,
            children: [
                {
                    path: '',
                    name: 'ExecutionCard',
                    component: ExecutionCard,
                },
                {
                    path: 'all-task',
                    name: 'ExecutionAllTask',
                    component: ExecutionAllTask,
                },
            ],
        },
        {
            path: '/products',
            name: 'ProductListPage',
            component: ProductListPage,
        },
        {
            path: '/mudi',
            name: 'MudiPage',
            component: MudiPage,
            children: [
                {
                    path: 'designideas',
                    name: 'MyDesignIdeas',
                    component: MyDesignIdeas,
                    beforeEnter: routeGuard,
                },
                {
                    path: 'workspace',
                    name: 'MyWorkspace',
                    component: MyWorkspace,
                    beforeEnter: routeGuard,
                },
                {
                    path: 'profile',
                    name: 'MyProfile',
                    component: MyProfile,
                    beforeEnter: routeGuard,
                },
            ],
            beforeEnter: routeGuard,
        },
        {
            path: '/terms',
            name: 'TCPage',
            component: TCPage,
        },
        // {
        //     path: '/privacy',
        //     name: 'Privacy',
        //     component: Privacy,
        // },
        {
            path: '/shipping',
            name: 'ShippingDetailsPage',
            component: ShippingDetailsPage,
        },
        {
            path: '/payment',
            name: 'PaymentPage',
            component: PaymentPage,
        },
        {
            path: '/faq',
            name: 'FAQPage',
            component: FAQPage,
        },
        {
            path: '/contact',
            name: 'ContactPage',
            component: ContactPage,
        },
        {
            path: '/thankyou/:id',
            name: 'ThankYouPage',
            component: ThankYouPage,
        },
        // {
        //     path: '/ehd/:id',
        //     name: 'EHDPage',
        //     component: EHDPage,
        //     beforeEnter: routeGuard,
        // },
        // {
        //     path: '/ehd/:id/:version',
        //     name: 'EHDPageV2',
        //     component: EHDPagev2,
        //     beforeEnter: routeGuard,
        // },
        {
            path: '/ehd/:id',
            name: 'EHDPage',
            component: EHDPage,
            beforeEnter: routeGuard,
        },
        {
            path: '/ehd/:id/:v',
            name: 'EHDPageRefactor',
            component: EHDPageRefactor,
            beforeEnter: routeGuard,
        },
        // {
        //     path: '/OGTagExp/:id',
        //     name: 'OGTagExp',
        //     component: OGTagExp,
        // },
        {
            path: '/finalise',
            name: 'FinaliseAndPlaceOrderPage',
            component: FinaliseAndPlaceOrderPage,
            beforeEnter: routeGuard,
        },
        {
            path: '/about-us',
            name: 'AboutUsPage',
            component: AboutUsPage,
        },
        {
            path: '/affiliate',
            name: 'AffiliatePage',
            component: AffiliatePage,
        },
        {
            path: '/affiliate-home',
            name: 'AffiliateHomePage',
            component: AffiliateHomePage,
        },
        {
            path: '/northen-heights',
            name: 'NorthenHeights',
            component: NorthenHeights,
        },
        {
            path: '/casa-amora',
            name: 'CasaAmora',
            component: CasaAmora,
        },
        {
            path: '/kitchen',
            name: 'KitchenFilter',
            component: KitchenFilter,
        },
        {
            path: '/:catchAll(.*)',
            name: '404',
            component: ErrorPage,
        },
        // {
        //     path:'/customisation',
        //     name:'CustomisationPage',
        //     component: PostSqCustomisation,
        // },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (window.innerWidth > 1136) {
                        resolve({ selector: to.hash, offset: { x: 0, y: 70 } })
                    } else {
                        resolve({ selector: to.hash, offset: { x: 0, y: 35 } })
                    }
                }, 1000)
            })
        }
        return { x: 0, y: 1 }
    },
    mode: 'history',
})
