import Api from 'utils/Api'

export function getThemesList(exclude_id){
    let url
    if(exclude_id){
        url = `InteriorDesign/theme/?image_req=True&exclude_id=${exclude_id}`
    }else{
        url = 'InteriorDesign/theme/?image_req=True'
    }
    return Api
        .get(url)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getThemeDetail(theme_id){
    return Api
        .get(`InteriorDesign/theme/${theme_id}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getRoomCategoriesList(){
    return Api
        .get('CADVisualization/collection-of-scene/room_type/')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getMostPopularWork(){
    return Api
        .get('CADVisualization/mostpopularwork/')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
