import Api from 'utils/Api'

// export function getEHDDetails(id){
//    return Api
//         .get(`v2/CADVisualization/ehd/get_ehd/?ehd_id=${id}`)
//         .then(response => {
//             return response.data
//         })
//         .catch(e => {
//             return { status: e.response.status, data: e.response.data }
//         })
// }
export function getEHDDetails(id) {
    return Api.get(`v2/CADVisualization/ehd/get_ehd/?ehd_id=${id}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
export function getEHDDetailsv3(obj) {
    return Api.get(
        `v3/CADVisualization/ehd/get_ehd/?ehd_id=${obj.ehd_id}${obj.scene_id?`&scene_id=${obj.scene_id}`:''}`,
    )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
export function submitKitchenData(obj) {
    return Api.post(`CADVisualization/update_kitchen_details/`, obj)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function fetchEhdDetails(ehd_id) {
    return Api.get(`v2/CADVisualization/ehd/get_ehd_og_data?ehd_id=${ehd_id}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
