<template>
    <div class="input-group-wrapper">
        <label class="label"
            >{{ labelName }}<span v-if="required">*</span></label
        >
        <div class="input-group country-input-wrap">
            <!-- <span class="input-icon" v-if="labelName == 'Mobile Number'">+ 91</span> -->

            <input
                :id="choiceId"
                class="input"
                :class="{
                    'input-error': error,
                }"
                :placeholder="placeholder"
                :data-required="required"
                :value="value"
                @input="handleInput"
                ref="input"
            />
            <div style="display: flex; align-items: center;">
                <svg
                    v-if="error"
                    class="input-error-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="#000"
                        d="M12 22c-1.978 0-3.911-.587-5.556-1.685-1.644-1.1-2.926-2.66-3.683-4.488-.757-1.827-.955-3.838-.569-5.778.386-1.94 1.338-3.722 2.737-5.12 1.398-1.399 3.18-2.351 5.12-2.737 1.94-.386 3.95-.188 5.778.57 1.827.756 3.389 2.038 4.488 3.682C21.413 8.09 22 10.022 22 12c-.003 2.651-1.057 5.193-2.932 7.068S14.65 21.998 12 22zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z"
                    />
                </svg>
            </div>
        </div>
        <div
            v-if="error"
            class="error"
            :class="{
                'error-show': error,
            }"
        >
            {{ msg }}
        </div>
        <template v-else>
        <div v-if="noerror==true" class="error error-hidden">no error</div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Input',
    props: {
        value: String,
        choiceId: {
            type: Number,
        },
        labelName: {
            type: String,
            reuqired: true,
        },
        inputType: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        placeholder: String,
        hint: String,
        msg: String,
        error: Boolean,
        noerror:Boolean,
    },
    mounted() {
        //this.focusInput()
    },
    methods: {
        handleInput(e) {
            this.$emit('input', e.target.value)
        },
        focusInput() {
            this.$refs.input.focus()
        },
    },
}
</script>

<style lang="scss">
@import './Input.scss';
</style>
