import {
    registration,
    login,
    socialAuth,
    generateOTP,
    customerCheck,
} from 'api/userApi'
import { getProject } from 'api/userApi'
import {
    DOMAIN_NAME,
    TOKEN_NAME,
    U_DATA,
    IS_LOGGEDIN,
    EMAIL,
    NAME,
    SQ,
    PQ,
    user_uuid,
} from 'utils/constants'
import Cookies from 'universal-cookie'
import router from '../../router'

const cookies = new Cookies()

const $appModule = {
    state: () => ({
        isLoggedIn: cookies.get(IS_LOGGEDIN)
            ? JSON.parse(cookies.get(IS_LOGGEDIN))
            : false,
        userData: cookies.get(U_DATA) ? cookies.get(U_DATA) : {},
        showAuthModal: false,
        authChange: false,
        isSQ: cookies.get(SQ) ? JSON.parse(cookies.get(SQ)) : false,
        isPQ: cookies.get(PQ) ? JSON.parse(cookies.get(PQ)) : false,
        requiredDomain: true,
    }),

    mutations: {
        setIsLoggedIn(state, payload) {
            state.isLoggedIn = payload
        },
        setUserData(state, payload) {
            state.userData = payload
        },
        setShowAuthModal(state, payload) {
            state.showAuthModal = payload
        },
        setAuthChange(state, payload) {
            state.authChange = payload
        },
        setSQ(state, payload) {
            state.isSQ = payload
        },
        setPQ(state, payload) {
            state.isPQ = payload
        },
        setRequiredDomain(state, payload) {
            state.requiredDomain = payload
        },
    },

    getters: {
        getIsLoggedIn: state => state.isLoggedIn,
    },

    actions: {
        async doCustomerCheck({ commit }, { number, email }) {
            return await customerCheck({ number, email })
        },
        async checkDomain({ commit }) {
            if (window.location.host != process.env.VUE_APP_DOMAIN_NAME) {
                commit('setRequiredDomain', true)
            } else {
                console.log(process.env.VUE_APP_DOMAIN_NAME," ",window.location.host )
                console.log(window.location.host != process.env.VUE_APP_DOMAIN_NAME,'b2b')
                commit('setRequiredDomain', false)
            }
        },

        async doRegister({ commit }, body) {
            return await registration(body).then(data => {
                if (data.responseCode === 200) {
                    const now = new Date()
                    now.setTime(now.getTime() + 30 * 24 * 60 * 60 * 1000)
                    cookies.set(TOKEN_NAME, data.payload.access_token, {
                        path: '/',
                        domain: DOMAIN_NAME,
                        expires: now,
                    })
                    cookies.set(IS_LOGGEDIN, true, {
                        path: '/',
                        domain: DOMAIN_NAME,
                        expires: now,
                    })
                    cookies.set(
                        U_DATA,
                        JSON.stringify({
                            firstname: data.payload.firstName,
                            lastname: data.payload.lastName,
                        }),
                        { path: '/', domain: DOMAIN_NAME, expires: now },
                    )

                    commit(
                        'setIsLoggedIn',
                        JSON.parse(cookies.get(IS_LOGGEDIN)),
                    )
                    commit('setUserData', cookies.get(U_DATA))
                    getProject().then(response => {
                        cookies.set(SQ, response.payload.is_sq_completed, {
                            path: '/',
                            domain: DOMAIN_NAME,
                            expires: now,
                        })
                        cookies.set(PQ, response.payload.is_pq_completed, {
                            path: '/',
                            domain: DOMAIN_NAME,
                            expires: now,
                        })

                        commit('setSQ', response.payload.is_sq_completed)
                        commit('setPQ', response.payload.is_pq_completed)
                    })
                    return data
                } else {
                    console.log(data.responseCode, data.responseMessage)
                    return data
                }
            })
        },

        async doLogin({ commit }, body) {
            return await login(body).then(data => {
                if (data.responseCode === 200) {
                    const now = new Date()
                    now.setTime(now.getTime() + 30 * 24 * 60 * 60 * 1000)
                    cookies.set(TOKEN_NAME, data.payload.access_token, {
                        path: '/',
                        domain: DOMAIN_NAME,
                        expires: now,
                    })
                    cookies.set(
                        U_DATA,
                        JSON.stringify({
                            firstname: data.payload.firstName,
                            lastname: data.payload.lastName,
                        }),
                        { path: '/', domain: DOMAIN_NAME, expires: now },
                    )
                    cookies.set(IS_LOGGEDIN, true, {
                        path: '/',
                        domain: DOMAIN_NAME,
                        expires: now,
                    })

                    commit(
                        'setIsLoggedIn',
                        JSON.parse(cookies.get(IS_LOGGEDIN)),
                    )
                    commit('setUserData', cookies.get(U_DATA))
                    getProject().then(response => {
                        cookies.set(SQ, response.payload.is_sq_completed, {
                            path: '/',
                            domain: DOMAIN_NAME,
                            expires: now,
                        })
                        cookies.set(PQ, response.payload.is_pq_completed, {
                            path: '/',
                            domain: DOMAIN_NAME,
                            expires: now,
                        })
                        commit('setSQ', response.payload.is_sq_completed)
                        commit('setPQ', response.payload.is_pq_completed)
                    })
                    return data
                } else {
                    console.log(data.responseCode, data.responseMessage)
                    return data
                }
            })
        },

        async doSocialAuth({ commit }, body) {
            return await socialAuth(body).then(data => {
                if (data.responseCode === 200) {
                    const now = new Date()
                    now.setTime(now.getTime() + 30 * 24 * 60 * 60 * 1000)
                    cookies.set(TOKEN_NAME, data.payload.access_token, {
                        path: '/',
                        domain: DOMAIN_NAME,
                        expires: now,
                    })
                    cookies.set(
                        U_DATA,
                        JSON.stringify({
                            firstname: data.payload.firstName,
                            lastname: data.payload.lastName,
                        }),
                        { path: '/', domain: DOMAIN_NAME, expires: now },
                    )
                    cookies.set(IS_LOGGEDIN, true, {
                        path: '/',
                        domain: DOMAIN_NAME,
                        expires: now,
                    })

                    commit(
                        'setIsLoggedIn',
                        JSON.parse(cookies.get(IS_LOGGEDIN)),
                    )
                    commit('setUserData', cookies.get(U_DATA))
                    getProject().then(response => {
                        cookies.set(SQ, response.payload.is_sq_completed, {
                            path: '/',
                            domain: DOMAIN_NAME,
                            expires: now,
                        })
                        cookies.set(PQ, response.payload.is_pq_completed, {
                            path: '/',
                            domain: DOMAIN_NAME,
                            expires: now,
                        })
                        commit('setSQ', response.payload.is_sq_completed)
                        commit('setPQ', response.payload.is_pq_completed)
                    })
                    return data
                } else {
                    console.log(data.responseCode, data.responseMessage)
                    return data
                }
            })
        },

        async generateOTP({ commit }, obj) {
            return await generateOTP(obj)
        },

        async toggleShowAuthModal({ commit, state }, params) {
            commit('setShowAuthModal', params)
            if (state.showAuthModal) {
                document.querySelector('body').style.overflow = 'hidden'
            } else {
                document.querySelector('body').style.overflow = 'auto'
            }
        },

        async toggleAuthChange({ commit }, params) {
            commit('setAuthChange', params)
        },

        async doLogout({ commit }) {
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('isLoggedIn')
            window.localStorage.removeItem('u_data')

            window.localStorage.removeItem('SQ')
            window.localStorage.removeItem('PQ')

            cookies.remove(IS_LOGGEDIN, { path: '/', domain: DOMAIN_NAME })
            cookies.remove(TOKEN_NAME, { path: '/', domain: DOMAIN_NAME })
            cookies.remove(U_DATA, { path: '/', domain: DOMAIN_NAME })

            cookies.remove(NAME, { path: '/', domain: DOMAIN_NAME })
            cookies.remove(EMAIL, { path: '/', domain: DOMAIN_NAME })
            cookies.remove(user_uuid, { path: '/', domain: DOMAIN_NAME })

            commit('setIsLoggedIn', false)
            commit('setUserData', {})
            commit('setSQ', false)
            commit('setPQ', false)
            cookies.remove(SQ, { path: '/', domain: DOMAIN_NAME })
            cookies.remove(PQ, { path: '/', domain: DOMAIN_NAME })
            router.push('/')
        },
    },
}

export default $appModule
