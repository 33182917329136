import axios from 'axios'
import {TOKEN_NAME} from 'utils/constants'

import Cookies from 'universal-cookie'

const cookies = new Cookies()

const Api = () => {
  const defaultOptions = {
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = cookies.get(TOKEN_NAME);
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });

  return instance;
};

export default Api();
