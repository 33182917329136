import { getMostPopularWork } from '../../api/themeApi'
// import { getTestimonialList } from '../../api/userApi'

const $appModule = {
    state: () => ({
        loading: false,
        // testimonialList: [],
        mostPopularWork: {},
        windowWidth: window.innerWidth,
    }),

    mutations: {
        // setTestimonalList(state, payload) {
        //     state.testimonialList = payload
        // },
        setMostPopularWork(state, payload) {
            state.mostPopularWork = payload
        },
        setWindowWidth(state, payload) {
            state.windowWidth = payload
        },
    },

    getters: {},

    actions: {
        // fetchTestimonialList({ commit }) {
        //     return getTestimonialList()
        //         .then(payload => {
        //             if (payload.status === 404) {
        //                 console.log('fetching failed')
        //             } else {
        //                 commit('setTestimonalList', payload)
        //                 return payload
        //             }
        //         })
        // },
        fetchMostPopularWork({ commit }) {
            return getMostPopularWork()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setMostPopularWork', payload)
                        return payload
                    }
                })
        },
        onResize({ commit }) {
            commit('setWindowWidth', window.innerWidth)
        },
    }
}

export default $appModule;