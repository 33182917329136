<template>
    <div id="app">
        <div v-if="windowWidth < 1136">
            <NavBarMobile
                @auth="toggleShowAuthModal(!showAuthModal)"
                :auth="isLoggedIn"
                :logOut="doLogout"
                :isSQ="isSQ"
                :isPQ="isPQ"
            />
        </div>
        <div v-else>
            <NavBar
                @auth="toggleShowAuthModal(!showAuthModal)"
                :auth="isLoggedIn"
                :logOut="doLogout"
                :isSQ="isSQ"
                :isPQ="isPQ"
            />
        </div>

        <Modal
            v-if="showAuthModal"
            :hideClose="hideClose"
            :backError="backError"
            ref="modal"
            transparent
            @close="toggleShowAuthModal(!showAuthModal)"
        >
            <template v-slot:default="slotProps">
                <SignupBox
                    v-if="authChange"
                    @sign-up="signUp"
                    :message="message"
                    :welcome="welcome"
                    :hideBack="slotProps.hideBack"
                    :back="slotProps.back"
                    :generate-otp="generateOTP"
                    :doCustomerCheck="doCustomerCheck"
                    @changeAuth="toggleAuthChange(!authChange)"
                    :authErr="authErr"
                    ref="signup"
                    :authErrMsg="authErrMsg"
                />
                <LoginBox
                    v-else
                    :message="message"
                    :hideBack="slotProps.hideBack"
                    :back="slotProps.back"
                    @login="login"
                    ref="login"
                    :generate-otp="generateOTP"
                    @changeAuth="toggleAuthChange(!authChange)"
                    :authErr="authErr"
                    :authErrMsg="authErrMsg"
                />
            </template>
        </Modal>
        <router-view
            :isSQ="isSQ"
            :isPQ="isPQ"
            :requiredDomain="requiredDomain"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import NavBar from 'componentsv2/NavBar'
import NavBarMobile from 'componentsv2/NavBarMobile'

import Modal from 'componentsv2/Modal'
import SignupBox from 'componentsv2/SignupBox'
import LoginBox from 'componentsv2/LoginBox'

export default {
    name: 'App',
    components: {
        NavBar,
        NavBarMobile,
        Modal,
        SignupBox,
        LoginBox,
    },
    data() {
        return {
            authErr: false,
            authErrMsg: '',
            message: false,
            hideClose: false,
            welcome: false,
        }
    },
    computed: {
        ...mapState({
            isLoggedIn: state => state.AuthModule.isLoggedIn,
            userData: state => state.AuthModule.userData,
            showAuthModal: state => state.AuthModule.showAuthModal,
            authChange: state => state.AuthModule.authChange,
            isSQ: state => state.AuthModule.isSQ,
            isPQ: state => state.AuthModule.isPQ,
            requiredDomain: state => state.AuthModule.requiredDomain,
            windowWidth: state => state.AppModule.windowWidth,
        }),
    },
    watch: {},
    created() {
        this.checkDomain()
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },
    destroyed() {
        this.$store.unregisterModule('AppModule')
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        ...mapActions({
            fetchTestimonialList: 'fetchTestimonialList',
            fetchMostPopularWork: 'fetchMostPopularWork',
            doRegister: 'doRegister',
            doLogin: 'doLogin',
            doLogout: 'doLogout',
            doSocialAuth: 'doSocialAuth',
            doCustomerCheck: 'doCustomerCheck',
            generateOTP: 'generateOTP',
            toggleShowAuthModal: 'toggleShowAuthModal',
            toggleAuthChange: 'toggleAuthChange',
            onResize: 'onResize',
            checkDomain: 'checkDomain',
        }),

        signUp(data) {
            if (data.authBy) {
                this.doSocialAuth(data).then(response => {
                    if (response.responseCode == 200) {
                        this.toggleShowAuthModal(false)
                        this.authErr = false
                        this.authErrMsg = ''
                        this.message = true
                        this.hideClose = true
                        var self = this
                        setTimeout(function() {
                            self.message = false
                            self.hideClose = false
                        }, 1000)
                    } else {
                        this.authErr = true
                        this.authErrMsg = response.responseMessage
                        this.$refs.login.showError(
                            true,
                            response.responseMessage,
                        )
                    }
                })
            } else {
                this.authErr = false
                this.authErrMsg = ''
                this.doRegister(data).then(response => {
                    if (response.responseCode == 200) {
                        this.authErr = false
                        this.authErrMsg = ''
                        this.message = true
                        this.hideClose = true
                        this.$refs.modal.backEvent()
                        var self = this
                        setTimeout(function() {
                            self.welcome = true
                            setTimeout(function() {
                                self.message = false
                                self.hideClose = false
                                self.welcome = false
                                self.toggleShowAuthModal(!self.showAuthModal)
                            }, 2000)
                        }, 1000)
                    } else {
                        this.authErr = true
                        this.authErrMsg = response.responseMessage
                        this.$refs.signup.showError(
                            true,
                            response.responseMessage,
                        )
                    }
                })
            }
        },

        login(data) {
            if (data.authBy) {
                this.doSocialAuth(data).then(response => {
                    if (response.responseCode == 200) {
                        this.toggleShowAuthModal(false)
                        this.authErr = false
                        this.authErrMsg = ''
                        this.message = true
                        this.hideClose = true
                        var self = this
                        setTimeout(function() {
                            self.message = false
                            self.hideClose = false
                        }, 1000)
                    } else {
                        this.authErr = true
                        this.authErrMsg = response.responseMessage
                        this.$refs.login.showError(
                            true,
                            response.responseMessage,
                        )
                    }
                })
            } else {
                this.doLogin(data).then(response => {
                    if (response.responseCode == 200) {
                        this.authErr = false
                        this.authErrMsg = ''
                        this.message = true
                        this.hideClose = true
                        this.$refs.modal.backEvent()
                        var self = this
                        setTimeout(function() {
                            self.message = false
                            self.hideClose = false
                            self.toggleShowAuthModal(false)
                        }, 1000)
                    } else {
                        this.authErr = true
                        this.authErrMsg = response.responseMessage
                        this.$refs.login.showError(
                            true,
                            response.responseMessage,
                        )
                    }
                })
            }
        },

        backError() {
            this.authErr = false
            this.authErrMsg = ''
        },
    },
}
</script>
<style lang="scss">
@import './App.scss';
</style>
