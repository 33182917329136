<template>
    <button
        class="r-btn"
        :class="{
            'r-btn-outline': outline,
            'r-btn-orange': orange,
            'r-btn-normal': normal,
            'r-btn-primary': primary,
            'r-btn-white': white,
            'r-disabled': disabled,
        }"
        :style="{
            paddingLeft: `${sidePadding}px`,
            paddingRight: `${sidePadding}px`,
        }"
        :disabled="disabled"
        @click="handleClick"
    >
        {{ name }}
        <span class="icon" v-if="$slots['icon']"
            ><slot name="icon"></slot
        ></span>
        <span class="icon-left" v-if="$slots['icon-left']"
            ><slot name="icon-left"></slot
        ></span>
    </button>
</template>

<script>
export default {
    name: 'Button',
    components: {},
    props: {
        name: {
            type: String,
            required: true,
        },
        handleClick: {
            type: Function,
            default: () => {},
        },
        outline: {
            type: Boolean,
        },
        orange: {
            type: Boolean,
        },
        primary: {
            type: Boolean,
        },
        normal: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        white: {
            type: Boolean,
        },
        icons: {
            type: Boolean,
        },
        sidePadding: {
            type: String,
        },
    },
}
</script>

<style lang="scss" scoped>
@import './Button.scss';
</style>
