var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"r-btn",class:{
        'r-btn-outline': _vm.outline,
        'r-btn-orange': _vm.orange,
        'r-btn-normal': _vm.normal,
        'r-btn-primary': _vm.primary,
        'r-btn-white': _vm.white,
        'r-disabled': _vm.disabled,
    },style:({
        paddingLeft: (_vm.sidePadding + "px"),
        paddingRight: (_vm.sidePadding + "px"),
    }),attrs:{"disabled":_vm.disabled},on:{"click":_vm.handleClick}},[_vm._v(" "+_vm._s(_vm.name)+" "),(_vm.$slots['icon'])?_c('span',{staticClass:"icon"},[_vm._t("icon")],2):_vm._e(),(_vm.$slots['icon-left'])?_c('span',{staticClass:"icon-left"},[_vm._t("icon-left")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }