import Vue from 'vue'
import Vuex from 'vuex'
import AppModule from 'store/modules/$appModule'
import AuthModule from 'store/modules/$authModule'
import MetaTagModule from 'store/modules/$metaTagModule'


Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        AppModule: AppModule,
        AuthModule: AuthModule,
        MetaTagModule: MetaTagModule,
    },
    strict: debug,
})