import Api from 'utils/Api'
// import {getCookie} from 'utils/getCookie'

export function getTestimonialList() {
    return Api
        .get('usermanagement/testimonials/')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function registration(obj) {
    return Api
        .post(
            'usermanagement/registration/',
            {
                "firstName": obj.name,
                "lastName": "",
                "mobileNumber": obj.number,
                "email": obj.email,
                "otp": obj.otp,
                "referenceId": obj.referenceId
            }
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function login(obj) {
    return Api
        .post(
            'usermanagement/login/',
            {
                "mobileNumber": obj.number,
                "otp": obj.otp,
                "referenceId": obj.referenceId
            }
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function socialAuth(obj) {
    return Api
        .post(
            'usermanagement/open-authorization/',
            obj
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function customerCheck(obj) {
    return Api
        .post(
            'usermanagement/users/customer_check/',
            {
                "phone_number": obj.number,
                "email_id": obj.email,
            }
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function generateOTP(obj) {
    let data = {}
    if (obj.referenceId) {
        data = {
            mobileNumber: obj.mobileNumber,
            otpType: obj.otpType,
            resend: obj.resend,
            referenceId: obj.referenceId,
        }
    } else {
        data = {
            mobileNumber: obj.mobileNumber,
            otpType: obj.otpType,
            resend: obj.resend,
        }
    }
    return Api
        .post(
            'usermanagement/generateOTP/', data
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getUserData() {
    return Api
        .get(`usermanagement/customer/1/`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
export function getProjectLocation(location) {
    return Api
        .get(`/quiz/get-project-for-location?location=${location}`)
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function updateUserData(obj) {
    return Api
        .post(
            'usermanagement/customer/update_user/',
            {
                "email_id": obj.email_id,
                // "dob" : obj.dob,
                // "gender" : obj.gender,
                // "address" : obj.address
            }
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
export function toggleOnlyKitchen(obj) {
    return Api
        .post(
            'usermanagement/toggle_furnish_area/',
            {
                "kitchen_only": obj,
            }
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
export function updateProject(obj) {
    return Api
        .post(
            'v2/quiz/survey-response/update_question_response/',
            {
                "question_id": obj.question_id,
                "response_choices": obj.response_choices
            }
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function deleteProject(id) {
    return Api
        .delete(
            `usermanagement/delete_userunit?id=${id}`
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}


export function getProjectQuestions() {
    return Api
        .get(
            'usermanagement/get_customer_linked_questions',
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getProject() {
    return Api
        .get(
            'usermanagement/customer/my_profile/',
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getDraft(ehd_id, scene_id) {
    return Api
        .get(
            `v2/CADVisualization/ehd/get_draft/?ehd_id=${ehd_id}&scene_id=${scene_id}`
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function restoreDraft(obj) {
    return Api
        .post(
            'CADVisualization/customization/restore_draft/',
            {
                ehd_id: obj.ehd_id,
                scene_id: obj.scene_id
            }
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function restoreDraftv3(obj) {
    return Api.post('v3/CADVisualization/customization/restore_draft/', {
        ehd_id: obj.ehd_id,
        scene_id: obj.scene_id,
    })
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function deleteDraft() {
    return Api
        .post(
            'CADVisualization/ehdPage/delete_draft/',
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function updateDraft() {
    return Api
        .post(
            'CADVisualization/ehdPage/delete_draft/',
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function requestProduct(text) {
    return Api
        .post(
            'Parser/requestedproduct/',
            {
                "link": "testproduct"
            }
        )
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}