import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from 'vue-meta'
import VueSocialSharing from 'vue-social-sharing'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueLazyload from 'vue-lazyload'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import GoogleAuth from 'utils/google_oAuth.js'
import VueEllipseProgress from 'vue-ellipse-progress'

library.add(fas);
library.add(fab);

Sentry.init({
  Vue,
  dsn: "https://3340053a8ae449d2bab786683812a393@o1186597.ingest.sentry.io/6306456",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "siloho.com",
        /^\//
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,
});

const gauthOption = {
  clientId: '386071903189-3imdq7f909ig6jctbtrebmkji1gdfbfs.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}

Vue.use(GoogleAuth, gauthOption)
Vue.use(VueTelInput)
Vue.use(VueMeta)
Vue.use(VueSocialSharing)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'http://hilongjw.github.io/vue-lazyload/dist/avatar/test15.jpg',
  loading: 'http://hilongjw.github.io/vue-lazyload/dist/avatar/test16.jpg',
  attempt: 1
})
Vue.use(VueEllipseProgress)

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('VueSlickCarousel', VueSlickCarousel);


//Router Config to set Title
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'SILOHO';
  next();
});

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD53EkI8Rs8BA4pgzvNUOSgFvpCvDvV1eE',
    libraries: "places"
  }
});

export const bus = new Vue();



new Vue({
  render: (h) => h(App),
  router,
  store
}).$mount("#app");