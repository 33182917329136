const DOMAIN_NAME = '.siloho.com'
const TOKEN_NAME =
    process.env.NODE_ENV == 'production'
        ? 'token'
        : process.env.NODE_ENV == 'development'
        ? 'tokenUat'
        : 'tokenLocal'
const U_DATA =
    process.env.NODE_ENV == 'production'
        ? 'uData'
        : process.env.NODE_ENV == 'development'
        ? 'uDataUat'
        : 'uDataLocal'
const IS_LOGGEDIN =
    process.env.NODE_ENV == 'production'
        ? 'isLoggedIn'
        : process.env.NODE_ENV == 'development'
        ? 'isLoggedInUat'
        : 'isLoggedInLocal'
const NAME =
    process.env.NODE_ENV == 'production'
        ? 'Name'
        : process.env.NODE_ENV == 'development'
        ? 'nameUat'
        : 'nameLocal'
const EMAIL =
    process.env.NODE_ENV == 'production'
        ? 'Email'
        : process.env.NODE_ENV == 'development'
        ? 'emailUat'
        : 'emailLocal'
const PRODUCT =
    process.env.NODE_ENV == 'production'
        ? 'Product'
        : process.env.NODE_ENV == 'development'
        ? 'productUat'
        : 'productLocal'
const SQ =
    process.env.NODE_ENV == 'production'
        ? 'SQ'
        : process.env.NODE_ENV == 'development'
        ? 'sqUat'
        : 'sqLocal'
const PQ =
    process.env.NODE_ENV == 'production'
        ? 'PQ'
        : process.env.NODE_ENV == 'development'
        ? 'pqUat'
        : 'pqLocal'
const user_uuid =
    process.env.NODE_ENV == 'production'
        ? 'user_uuid'
        : process.env.NODE_ENV == 'development'
        ? 'user_uuidUat'
        : 'user_uuidLocal'

const SNAKE_LOADER = 'snake_loader'
const VISUAL_LOADER = 'visual_loader'
const WAVE_LOADER = 'wave_loader'

export {
    DOMAIN_NAME,
    TOKEN_NAME,
    U_DATA,
    IS_LOGGEDIN,
    NAME,
    EMAIL,
    PRODUCT,
    SQ,
    PQ,
    user_uuid,
    SNAKE_LOADER,
    VISUAL_LOADER,
    WAVE_LOADER,
}
