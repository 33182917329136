<template>
    <div class="navbar">
        <router-link to="/">
            <div class="nav-logo">
                <img src="@/assets/images/Group 479.png" alt="SILOHO" />
            </div>
        </router-link>
        <ul class="nav-menu">
            <router-link
                :to="{
                    name: 'AboutUsPage',
                }"
            >
                <li class="nav-item">
                    About Us
                </li>
            </router-link>
            <router-link
                :to="{
                    name: 'DesignIdeasListPage',
                }"
            >
                <li class="nav-item">Design Ideas</li>
            </router-link>
            <router-link :to="{ path: '/', hash: '#howitworks' }">
                <li class="nav-item">
                    How It Works
                </li>
            </router-link>

            <router-link :to="{ path: '/', hash: '#successstories' }">
                <li class="nav-item">
                    Success Stories
                </li>
            </router-link>

            <router-link
                :to="{
                    name: 'BuildersListPage',
                }"
            >
                <li class="nav-item partner-link">
                    Our Partners
                </li>
            </router-link>
            <router-link :to="{ path: '/', hash: '#vendors' }">
                <li class="nav-item">
                    Our Vendors
                </li>
            </router-link>
        </ul>
        <ul class="nav-buttons">
            <li class="right-menu-stylebtn">
                <Button
                    v-if="isSQ == true && isPQ == true"
                    name="Explore Designs"
                    primary
                    target="_self"
                    :handleClick="openMDI"
                />
                <Button
                    v-else-if="isSQ == true && isPQ == false"
                    name="Explore Designs"
                    primary
                    :handleClick="openMDI"
                    target="_self"
                />
                <Button
                    v-else
                    name="Create project"
                    primary
                    :handleClick="openQuiz"
                    target="_self"
                />
            </li>
            <li class="right-menu-loginbtn">
                <Button
                    outline
                    name="Sign in / Sign up"
                    @click.native="$emit('auth')"
                    v-if="!auth"
                />
                <div class="a-login" v-else>
                    <div class="userMenu" @click.stop="showUserDropDown">
                        <div class="selected-value">
                            <span class="user">
                                {{ userData.firstname }}
                            </span>
                            <img
                                src="@/assets/images/profile.svg"
                                alt=""
                                class="profile-image"
                            />
                        </div>

                        <div class="options" v-if="userDropDown">
                            <router-link
                                :to="{
                                    name: 'MyWorkspace',
                                }"
                                active-class="active"
                                class="option"
                            >
                                My Workspace
                            </router-link>
                            <router-link
                                :to="{
                                    name: 'MyDesignIdeas',
                                }"
                                active-class="active"
                                class="option"
                            >
                                My Design Ideas
                            </router-link>

                            <router-link
                                :to="{
                                    name: 'MyProfile',
                                }"
                                active-class="active"
                                class="option"
                            >
                                My Profile
                            </router-link>

                            <router-link
                                to="/contact"
                                active-class="active"
                                class="option"
                            >
                                Contact Us
                            </router-link>

                            <div class="option" @click="logOut">
                                Logout
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Button from 'componentsv2/Button'

export default {
    name: 'NavBar',
    props: {
        auth: {
            type: Boolean,
        },
        logOut: {
            type: Function,
            default: () => {},
        },
        isSQ: Boolean,
        isPQ: Boolean,
    },
    components: {
        Button,
    },
    data() {
        return {
            quizUrl:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_QUIZ_BASE_URL_B2B
                    : process.env.VUE_APP_QUIZ_BASE_URL,
            baseUrl:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_BASE_URL_B2B
                    : process.env.VUE_APP_BASE_URL,
            showModal: false,
            userDropDown: false,
            breadcrumbList: [],
        }
    },
    watch: {
        $route() {
            this.userDropDown = false
            let header = document.querySelector('.navbar')
            if (this.userDropDown) {
                header.classList.add('navbar-shadow')
            } else {
                header.classList.remove('navbar-shadow')
            }
            this.updateList()
        },
    },
    computed: {
        ...mapState({
            userData: state => state.AuthModule.userData,
        }),
    },
    methods: {
        ...mapActions({}),
        showUserDropDown() {
            this.userDropDown = !this.userDropDown
            let header = document.querySelector('.navbar')
            if (this.userDropDown) {
                header.classList.add('navbar-shadow')
            } else {
                header.classList.remove('navbar-shadow')
            }
        },
        openQuiz() {
            window.open(`${this.quizUrl}?startQuiz=true`, '_self')
        },
        openMDI() {
            window.open(`${this.baseUrl}mudi/designideas`, '_self')
        },
        toggleLoginModal() {
            this.showModal = !this.showModal
        },
        handleScroll(e) {
            let header = document.querySelector('.navbar')
            let sticky = header.offsetTop
            if (window.pageYOffset > sticky + 1) {
                if (this.breadcrumbList == null) {
                    document.querySelector('#app').style.marginTop = '71px'
                } else {
                    document.querySelector('#app').style.marginTop = '85px'
                }
                header.classList.add('navbar-fixed')

                this.userDropDown = false
            } else {
                header.classList.remove('navbar-fixed')
                document.querySelector('#app').style.marginTop = '1px'
            }
        },
        updateList() {
            this.breadcrumbList = this.$route.meta.breadcrumb
        },
    },
    created() {
        window.addEventListener('click', e => {
            if (!this.$el.contains(e.target)) {
                this.userDropDown = false
                let header = document.querySelector('.navbar')
                header.classList.remove('navbar-shadow')
            }
        })
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
}
</script>

<style lang="scss" scoped>
@import './NavBar.scss';
</style>
