<template>
    <div>
        <div class="login-container" v-if="!message">
            <div class="otp-view" v-if="hideBack">
                <div class="head">
                    <h1>Please enter the verification code</h1>
                    <p>
                        A 4 digit code as been sent to
                        {{
                            validPhone.number
                                .slice(1)
                                .replace(/\d{8}/gm, '********')
                        }}
                    </p>
                    <p class="login-error" v-if="loginErr">
                        {{ msg }}
                    </p>
                </div>
                <div class="sub">
                    <Input v-model="otp" :error="otpErr" :msg="otpmsg" />
                    <div class="btn">
                        <Button
                            primary
                            name="Verify"
                            @click.native="handleLogin"
                            :disabled="disabled"
                        />
                    </div>
                </div>
                <div v-if="timerEnabled" class="resendText" > Resend OTP in {{timerCount}}</div>
                <div v-else class="resend" @click="getOtp('resend',true)">Resend OTP</div>
            </div>
            <div class="loginbox-view" v-else>
                <div class="login-box">
                    <h1 class="head">Hello, Welcome Back!</h1>
                    <p class="sub-head">
                        Don't have a account yet?
                        <span class="color-text" @click="$emit('changeAuth')">
                            Sign up for free
                        </span>
                    </p>

                    <p class="login-error" v-if="loginErr">
                        {{ msg }}
                    </p>
                    <div class="login-form">
                        <div class="num">
                            <label>Mobile Number</label>
                            <vue-tel-input
                                v-model="phone"
                                v-bind="bindProps"
                                @open="onDropdownOpen(true)"
                                @close="onDropdownOpen(false)"
                                @validate="numberChanged"
                            >
                                <template v-slot:arrow-icon>
                                    <template v-if="open">
                                        <svg
                                            width="25"
                                            height="24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="m7.275 14 5-5 5 5h-10z"
                                                fill="#000"
                                            />
                                        </svg>
                                    </template>
                                    <template v-else>
                                        <svg
                                            width="25"
                                            height="24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="m7.275 10 5 5 5-5h-10z"
                                                fill="#000"
                                            />
                                        </svg>
                                    </template>
                                </template>
                            </vue-tel-input>
                            <span class="invalidMsg">{{
                                bindProps.invalidMsg
                            }}</span>
                        </div>
                        <div class="login-btn">
                            <Button
                                name="Continue"
                                primary
                                class="sigup-btn"
                                :disabled="disabled"
                                @click.native="getOtp('login',false)"
                            />
                        </div>
                    </div>
                    <div class="terms">
                        <p>
                            By creating an account, I agree with SILOHO’s <br />
                            <router-link
                                :to="{
                                    name: 'TCPage',
                                }"
                            >
                                <a @click="toggleModal()">Privacy Policy</a>
                            </router-link>
                            and
                            <router-link
                                :to="{
                                    name: 'TCPage',
                                }"
                            >
                                <a @click="toggleModal()"
                                    >Terms and Conditions.</a
                                >
                            </router-link>
                        </p>
                    </div>
                    <div
                        class="login-choices"
                        style="width: 100%; text-align: center; margin: 15px 0;"
                    >
                        <span> or sign in with </span>
                    </div>
                    <SocialLogin @socialAuth="handleSocialAuth" />
                </div>
            </div>
        </div>
        <div class="message-conatiner" v-else>
            <div class="validate-success">
                <span>
                    <svg
                        width="75"
                        height="72"
                        viewBox="0 0 25 24"
                        preserveAspectRatio="xMaxYMax meet"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12.275 22a10 10 0 1 1 10-10 10.011 10.011 0 0 1-10 10zm0-18a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8zm-2 13-5-5 1.41-1.41 3.59 3.58 6.59-6.59L18.275 9l-8 8z"
                            fill="#ff6100"
                        />
                    </svg>
                </span>
                <span class="title">One-Time Password validated</span>
            </div>
        </div>
    </div>
</template>

<script>
import Input from 'componentsv2/Input'
import Button from 'componentsv2/Button'
import { mapState, mapActions } from 'vuex'
import SocialLogin from 'componentsv2/SocialLogin'
let interval =''

export default {
    name: 'LoginBox',
    props: {
        generateOtp: {
            type: Function,
            default: () => {},
        },
        back: {
            type: Function,
            default: () => {},
        },
        message: Boolean,
        hideBack: Boolean,
        authErr: Boolean,
        authErrMsg: String,
    },
    components: {
        Input,
        Button,
        SocialLogin,
    },
    data() {
        return {
            bindProps: {
                autoFormat: false,
                defaultCountry: 'IN',
                invalidMsg: '',
                enabledFlags: true,
                required: true,
                onlyCountries: ['IN', 'US', 'GB', 'AU', 'CA', 'SG', 'AE'],
                name: 'phone',
                maxLen: 15,
                autocomplete: 'on',
                validCharactersOnly: true,
                styleClasses: 'mobile-number',
                dropdownOptions: {
                    showFlags: true,
                    showDialCodeInSelection: true,
                    width: '300px',
                    showDialCodeInList: true,
                    showSearchBox: true,
                },
                inputOptions: {
                    placeholder: 'Phone Number',
                    styleClasses: 'country-number',
                },
            },
            loginErr: null,
            msg: '',
            phone: '',
            phoneNumberErr: null,
            phoneNumbermsg: '',
            otp: '',
            otpErr: null,
            otpmsg: '',
            referenceId: '',
            disabled: true,
            open: false,
            validPhone: {},
            timerEnabled: false,
            timerCount: '00:30',
        }
    },
    watch: {
        authErr() {
            this.loginErr = this.authErr
            if (this.authErr == true) {
                this.disabled = true
            }
        },

        authErrMsg() {
            this.msg = this.authErrMsg
        },

        // phone(e) {
        //     // var number = new RegExp('^\+(?:[0-9]●?){6,14}[0-9]$')
        //     var check = e.replace(/\s+/g, '')
        //     if (check.length != 0) {
        //         this.bindProps.invalidMsg = ''
        //         document.querySelector('.invalidMsg').style.visibility =
        //             'hidden'
        //     } else {
        //         this.bindProps.invalidMsg = 'Please enter correct phone number'
        //         document.querySelector('.invalidMsg').style.visibility =
        //             'visible'
        //     }
        //     this.isValidated()
        // },

        otp(e) {
            this.loginErr = false
            this.msg = ''
            var number = new RegExp('^[0-9]*$')
            if ((number.test(e) && e.length == 4 ) ) {
                this.otpErr = false
                this.otpmsg = ''
                this.disabled = false
            } else if(this.otp==null){
                this.otpErr = false
                this.otpmsg = ''
            }
            else {
                this.otpErr = true
                this.otpmsg =
                    'OTP should be number and only contain 4 character'
                this.disabled = true
            }
        },
    },
    computed: {
        ...mapState({
            showAuthModal: state => state.AuthModule.showAuthModal,
        }),
    },
    methods: {
        ...mapActions({
            toggleShowAuthModal: 'toggleShowAuthModal',
        }),
        startCountDown(bool) {
            this.timerEnabled = bool
            let self=this
            if(this.timerCount!='00:30'){
                this.timerCount='00:30'
            }
            clearInterval(interval)
            interval=setInterval(function(){         
                let timer=self.timerCount.split(':')      
                var minutes = timer[0];
                var seconds = timer[1];
                seconds -= 1;
                if (minutes < 0) return;
                else if (seconds < 0 && minutes != 0) {
                    minutes -= 1;
                    seconds = 59;
                }
                else if (seconds < 10 ) seconds = '0' + seconds
                self.timerCount=minutes+':'+seconds
                if(minutes==0 && seconds==0){
                    self.timerEnabled=false
                    clearInterval(interval)
                }
            },1000)     
        },

        isValidated() {
            if (this.bindProps.invalidMsg == '') {
                this.disabled = false
            } else {
                this.disabled = true
            }
        },

        showError(status, msg) {
            this.loginErr = status
            this.msg = msg
        },

        onDropdownOpen(a) {
            this.open = a
        },

        numberChanged(e) {
            this.validPhone = e
            if(this.validPhone.valid!=null){
                if(this.validPhone.valid==true){
                    this.bindProps.invalidMsg = ''
                    document.querySelector('.invalidMsg').style.visibility =
                        'hidden'
                } else {
                    this.bindProps.invalidMsg = 'Please enter correct phone number'
                    document.querySelector('.invalidMsg').style.visibility =
                        'visible'
                }
                this.isValidated()
            }
            
        },

        getOtp(resend,resendOTP) {
            this.disabled = true
            let obj={
                mobileNumber: this.validPhone.number,
                otpType: 'login',
                resend: resendOTP,
                referenceId: this.referenceId
            }
            this.startCountDown(true)           
            this.generateOtp(obj).then(data => {
                if (data.responseCode == 200) {   
                    this.referenceId= data.payload.referenceId             
                    if (resend === 'login') {
                        this.back()
                        this.otp=null                          
                    }
                    this.loginErr = false
                    this.msg = ''
                } else {
                    this.loginErr = true
                    this.msg = data.responseMessage
                }
            })
        },

        toggleModal() {
            this.toggleShowAuthModal(false)
            const hamburger = document.querySelector('.hamburger')
            const navMenu = document.querySelector('.nav-menu')
            document.querySelector('body').style.overflow = 'auto'
            hamburger.classList.remove('active')
            navMenu.classList.remove('active')
        },

        handleLogin() {
            this.$emit('login', {
                number: this.validPhone.number,
                otp: this.otp,
                referenceId: this.referenceId,
            })
        },

        handleSocialAuth(e) {
            this.$emit('login', e)
        },
    },
}
</script>

<style lang="scss" scoped>
@import './LoginBox.scss';
</style>
